import React from "react";

const IconDzen: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_281_7511)">
        <mask
          id="mask0_281_7511"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <path d="M15.9433 0H0.0341797V15.9091H15.9433V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_281_7511)">
          <path
            d="M7.95513 15.9063H8.01855C11.1773 15.9063 13.0108 15.5883 14.3095 14.3274C15.6337 12.9899 15.9395 11.144 15.9395 7.99762V7.90865C15.9395 4.76317 15.6337 2.92966 14.3095 1.57889C13.0117 0.31805 11.165 0 8.0195 0H7.95608C4.79736 0 2.96288 0.31805 1.66419 1.57889C0.339924 2.9164 0.0341797 4.76317 0.0341797 7.90865V7.99762C0.0341797 11.1431 0.339924 12.9767 1.66419 14.3274C2.95059 15.5883 4.79736 15.9063 7.95513 15.9063Z"
            fill="#202022"
          />
          <path
            d="M14.0461 7.83253C14.0461 7.77195 13.9969 7.7218 13.9363 7.71896C11.7623 7.63661 10.4392 7.35931 9.51356 6.43367C8.58601 5.50616 8.30964 4.18207 8.2273 2.00334C8.22541 1.94277 8.17524 1.89355 8.11372 1.89355H7.86008C7.79951 1.89355 7.74934 1.94277 7.74651 2.00334C7.66416 4.18112 7.3878 5.50616 6.46028 6.43367C5.53371 7.36025 4.21151 7.63661 2.03752 7.71896C1.97695 7.72085 1.92773 7.77101 1.92773 7.83253V8.08618C1.92773 8.14675 1.97695 8.19691 2.03752 8.19975C4.21151 8.28209 5.53465 8.5594 6.46028 9.48503C7.3859 10.4107 7.66227 11.731 7.74556 13.9011C7.74745 13.9617 7.79761 14.011 7.85913 14.011H8.11372C8.1743 14.011 8.22446 13.9617 8.2273 13.9011C8.31059 11.731 8.58695 10.4107 9.51262 9.48503C10.4392 8.55845 11.7613 8.28209 13.9353 8.19975C13.9959 8.19786 14.0452 8.1477 14.0452 8.08618V7.83253H14.0461Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_281_7511">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDzen;
