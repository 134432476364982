import React, { useEffect, useState } from "react";
import "./feed-statistics-styles.scss";
import { useSelector } from "react-redux";
import { getReferences, getThread } from "../../redux/selectors";
import { getFeedFilter } from "../../redux/selectors/feed-filter.selectors";
import { summaryService } from "../../api";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  referencesFilter: number[];
};
const FeedStatistics: React.FC<Props> = ({ referencesFilter }: Props) => {
  const thread = useSelector(getThread);
  const references = useSelector(getReferences);
  //const feedStatistics = useSelector(getFeedStatistics)
  const [postCount, setPostCount] = useState({
    fb: 0,
    gs: 0,
    ig: 0,
    tg: 0,
    total: 0,
    tw: 0,
    vk: 0,
    yt: 0,
    ok: 0,
  });
  const currentFeedFilter = useSelector(getFeedFilter);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (thread)
      summaryService
        .getPostCountLight({
          thread_id: thread?.id,
          from: currentFeedFilter.from,
          to: currentFeedFilter.to,
          filter: {
            referenceFilter: window.location.pathname.includes("thread")
              ? references
              : referencesFilter,
            repostoption: "whatever",
            trustoption: currentFeedFilter.tonal,
            network_id:
              currentFeedFilter.networks.length > 0
                ? currentFeedFilter.networks
                : currentFeedFilter.network_id,
          },
        })
        .then((resp) => {
          setIsLoading(false);
          setPostCount(resp);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [thread, referencesFilter, currentFeedFilter, references]);

  let minusFilter = 0;

  if (currentFeedFilter) {
    console.log("resp", currentFeedFilter);
    if (currentFeedFilter.networks.indexOf(1) == -1)
      minusFilter = minusFilter + postCount.vk;

    if (currentFeedFilter.networks.indexOf(2) == -1)
      minusFilter = minusFilter + postCount.tw;

    minusFilter = minusFilter + postCount.fb;
    minusFilter = minusFilter + postCount.ig;
    //minusFilter = minusFilter + postCount.ok

    if (currentFeedFilter.networks.indexOf(4) == -1)
      minusFilter = minusFilter + postCount.gs;

    if (currentFeedFilter.networks.indexOf(5) == -1)
      minusFilter = minusFilter + postCount.tg;

    //if (currentFeedFilter.networks.indexOf(7) == -1)
    //  minusFilter = minusFilter + feedStatistics.ig

    if (currentFeedFilter.networks.indexOf(8) == -1)
      minusFilter = minusFilter + postCount.yt;

    if (currentFeedFilter.networks.indexOf(10) == -1)
      minusFilter = minusFilter + postCount.ok;
  }

  const totalScore = postCount.total - minusFilter;

  return isLoading ? (
    <div className="feed-posts-loader">
      <LoadingOutlined />
    </div>
  ) : (
    <div className="feed-statistics">
      <div className="feed-statistics-item">
        <div className="feed-statistics-item__value">{totalScore}</div>
        <div className="feed-statistics-item__desc">За период</div>
      </div>
      {currentFeedFilter.networks.length > 1 &&
      currentFeedFilter.networks.indexOf(1) != -1 ? (
        <div className="feed-statistics-item">
          <div className="feed-statistics-item__value">{postCount.vk}</div>
          <div className="feed-statistics-item__desc">Вконтакте</div>
        </div>
      ) : (
        ""
      )}
      {currentFeedFilter.networks.length > 1 &&
      currentFeedFilter.networks.indexOf(2) != -1 ? (
        <div className="feed-statistics-item">
          <div className="feed-statistics-item__value">{postCount.tw}</div>
          <div className="feed-statistics-item__desc">Twitter</div>
        </div>
      ) : (
        ""
      )}
      {/*currentFeedFilter.networks.length > 1 && currentFeedFilter.networks.indexOf(7) != -1 ?
          <div className="feed-statistics-item">
            <div className="feed-statistics-item__value">{feedStatistics.ig}</div>
            <div className="feed-statistics-item__desc">Instagram</div>
          </div>
          : ''}
          {currentFeedFilter.networks.length > 1 && currentFeedFilter.networks.indexOf(3) != -1 ?
          <div className="feed-statistics-item">
            <div className="feed-statistics-item__value">{feedStatistics.fb}</div>
            <div className="feed-statistics-item__desc">Facebook</div>
          </div>
          : ''*/}
      {currentFeedFilter.networks.length > 1 &&
      currentFeedFilter.networks.indexOf(5) != -1 ? (
        <div className="feed-statistics-item">
          <div className="feed-statistics-item__value">{postCount.tg}</div>
          <div className="feed-statistics-item__desc">Telegram</div>
        </div>
      ) : (
        ""
      )}
      {currentFeedFilter.networks.length > 1 &&
      currentFeedFilter.networks.indexOf(8) != -1 ? (
        <div className="feed-statistics-item">
          <div className="feed-statistics-item__value">{postCount.yt}</div>
          <div className="feed-statistics-item__desc">YouTube</div>
        </div>
      ) : (
        ""
      )}
      {currentFeedFilter.networks.length > 1 &&
      currentFeedFilter.networks.indexOf(10) != -1 ? (
        <div className="feed-statistics-item">
          <div className="feed-statistics-item__value">{postCount.ok}</div>
          <div className="feed-statistics-item__desc">Одноклассники</div>
        </div>
      ) : (
        ""
      )}
      {currentFeedFilter.networks.length > 1 &&
      currentFeedFilter.networks.indexOf(4) != -1 ? (
        <div className="feed-statistics-item">
          <div className="feed-statistics-item__value">{postCount.gs}</div>
          <div className="feed-statistics-item__desc">СМИ</div>
        </div>
      ) : (
        ""
      )}
      {/* <div className="feed-statistics-item"> */}
      {/*  <div className="feed-statistics-item__value">{feedStatistics.attendance}</div> */}
      {/*  <div className="feed-statistics-item__desc">Аудитория</div> */}
      {/* </div> */}
    </div>
  );
};

export default FeedStatistics;
