import React, { useState } from "react";
import { Image } from "antd";

type Props = {
  item: any;
};

const LoadedImage: any = ({ item }: Props) => {
  const [imgNotLoaded, setImgNotLoaded] = useState(false);

  return (
    <>
      {item && !imgNotLoaded ? (
        <Image
          width={200}
          src={item?.url}
          fallback={item?.url}
          onError={() => setImgNotLoaded(true)}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default LoadedImage;
