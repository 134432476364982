import React from "react";

const IconOk: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_281_7617)">
        <path
          d="M16.0821 13.6586C16.0821 14.9518 15.0338 16 13.7407 16H2.34137C1.04826 16 0 14.9518 0 13.6586V2.34137C0 1.04826 1.04826 0 2.34137 0H13.7407C15.0338 0 16.0821 1.04826 16.0821 2.34137V13.6586Z"
          fill="#FAAB62"
        />
        <path
          d="M15.9999 13.6006C15.9999 14.8804 14.9624 15.9179 13.6825 15.9179H2.4003C1.12048 15.9179 0.0830078 14.8804 0.0830078 13.6006V2.39957C0.0830078 1.11975 1.12053 0.0822754 2.4003 0.0822754H13.6825C14.9624 0.0822754 15.9999 1.11979 15.9999 2.39957V13.6006Z"
          fill="#F7931E"
        />
        <path
          d="M8.03239 2.39136C6.43853 2.39136 5.14648 3.68344 5.14648 5.27726C5.14648 6.87112 6.43853 8.16329 8.03239 8.16329C9.62625 8.16329 10.9183 6.87112 10.9183 5.27726C10.9183 3.68344 9.62625 2.39136 8.03239 2.39136ZM8.03239 6.47031C7.37354 6.47031 6.83942 5.93615 6.83942 5.2773C6.83942 4.61846 7.37354 4.08433 8.03239 4.08433C8.69123 4.08433 9.22536 4.61846 9.22536 5.2773C9.22536 5.93615 8.69123 6.47031 8.03239 6.47031Z"
          fill="white"
        />
        <path
          d="M9.05099 10.4593C10.2063 10.2239 10.8987 9.67677 10.9353 9.6474C11.2734 9.37627 11.3277 8.88242 11.0566 8.5443C10.7855 8.20623 10.2917 8.15192 9.9535 8.42301C9.94636 8.42879 9.20793 8.99525 7.99295 8.99607C6.77801 8.99525 6.02397 8.42879 6.01682 8.42301C5.67866 8.15192 5.18485 8.20623 4.91376 8.5443C4.64263 8.88242 4.69694 9.37627 5.03502 9.6474C5.07215 9.67718 5.79306 10.2386 6.98079 10.4684L5.32547 12.1983C5.02465 12.5103 5.0337 13.007 5.34567 13.3079C5.49794 13.4547 5.69419 13.5277 5.89028 13.5277C6.09587 13.5277 6.30125 13.4473 6.45521 13.2876L7.99299 11.6495L9.68609 13.2983C9.99286 13.6045 10.4896 13.604 10.7958 13.2974C11.102 12.9907 11.1016 12.4939 10.7949 12.1877L9.05099 10.4593Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_281_7617">
          <rect width="16.0821" height="16" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconOk;
