import { AnyAction } from 'redux'
import * as actionType from './types'

import { resetUser } from './user.actions'
import { setSelectedReferencesAll } from './selected-references.actions'
import LogRocket from 'logrocket'
import { setIsLoggedIn } from './auth.actions'
import { referencesService, threadService, userService } from '../../api'

export const setAppIsInitialize = (value:any): AnyAction => ({ type: actionType.SET_APP_IS_INITIALIZE, payload: value })
export const setThread = (thread:any): AnyAction => ({ type: actionType.SET_THREAD, payload: thread })
export const setProduct = (product:any): AnyAction => ({ type: actionType.SET_PRODUCT, payload: product })
export const setThreads = (threads:any): AnyAction => ({ type: actionType.SET_THREADS, payload: threads })
export const setAppIsMobile = (value:any): AnyAction => ({ type: actionType.SET_APP_IS_MOBILE, payload: value })
export const showMenu = (): AnyAction => ({ type: actionType.SHOW_MENU })
export const hideMenu = (): AnyAction => ({ type: actionType.HIDE_MENU })
export const setReferences = (references:any): AnyAction => ({ type: actionType.SET_REFERENCES, payload: references })
export const setReferencesFilter = (referencesId:any): AnyAction => ({ type: actionType.SET_REFERENCES_FILTER, payload: referencesId })

export const getAppData = () => async (dispatch:any, getState:any) => {
  Promise.all([
    threadService.loadThread(),
    threadService.loadThreads(),
  ])
    .then(([
      thread,
      threads,
    ]) => {
      dispatch(setThread(thread))
      dispatch(setThreads(threads))
      dispatch(setAppIsInitialize(false))
    })
    .catch(() => {
      dispatch(resetUser())
      dispatch(setIsLoggedIn(false))
      dispatch(setAppIsInitialize(false))
    })
}

export const appInitialize = () => async (dispatch:any, getState:any) => {
  const { user } = getState()
  if (user) {
    userService.check()
      .then((resp) => {
        dispatch(setIsLoggedIn(true))
        LogRocket.identify(resp.email)
        dispatch(getAppData())
      })
      .catch(() => {
        dispatch(setIsLoggedIn(false))
        dispatch(setAppIsInitialize(false))
      })
  } else {
    dispatch(setIsLoggedIn(false))
    dispatch(setAppIsInitialize(false))
  }
}
