import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/ru_RU";
import Link from "./icons/Link";
import { useSelector } from "react-redux";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { getProduct, getReferencesFilter, getThread } from "../redux/selectors";
import { summaryService } from "../api";

const ViewersTable: React.FC = () => {
  const [value, setValue] = useState("today");
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false);
  const feedFilter = useSelector(getFeedFilter);
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const product = useSelector(getProduct);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const thread = useSelector(getThread);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];

  useEffect(() => {
    setLoading(true);
    if (thread) {
      let params = {
        product_id: product,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
      };
      summaryService
        .loadProductCityListReverse(params)
        .then((resp) => {
          setCities(resp);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString, product]);

  cities?.map((city) => console.log("city", city));

  const data: any = [];
  cities?.sort((a:any, b:any) => a.rating < b.rating ? 1 : -1).map((city: any, id: number) => {
    data.push({
      key: id + 1,
      title: city.city,
      rating: city.rating,
      posts: city.posts,
    });
  });

  const columns = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Город",
      dataIndex: "title",
      key: "title",
    },
    {
      title: (
        <Tooltip
          color={"#7856FE"}
          title="Рейтинг - показатель активности аудитории из данного города, рассчитываемый на основе лайков/репостов/комментариев под постами"
        >
          <div>
            Рейтинг
            <InfoCircleOutlined className="info" />
          </div>
        </Tooltip>
      ),
      dataIndex: "rating",
      key: "rating",
    },
  ];

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  return (
    <div className="white-block-small">
      <div className="flex-space">
        <div>
          <p className="block-title">
            Геолокация по просмотрам
            <InfoCircleOutlined className="info" onClick={showDrawer} />
          </p>
          <div className="flex">
            <ConfigProvider
              theme={{
                components: {
                  Radio: {
                    buttonSolidCheckedColor: "#7856FE",
                    buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                    colorPrimary: "rgba(0, 0, 0, 0.1)",
                    buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                  },
                },
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChange}
                value={value}
                optionType="button"
                buttonStyle="solid"
              />
            </ConfigProvider>

            {value == "period" && (
              <RangePicker
                format={"DD-MM-YYYY"}
                onChange={onPeriodChange}
                style={{ width: "278px" }}
                className="choose-period"
                allowClear={false}
                locale={locale}
              />
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader">
          <LoadingOutlined />
        </div>
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 12,
              },
            },
          }}
        >
          <Table
            className="big-table"
            bordered={false}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </ConfigProvider>
      )}
      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <p className="purple-border">
          Демонстрирует статистику просмотров к публикациям с упоминаниями
          компании в зависимости от геолокации аудитории
        </p>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default ViewersTable;
