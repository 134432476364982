import { AnyAction } from 'redux'
import * as actionType from '../actions/types'

export interface UserState {
  group: string
  name: string
  email: string
  uid: number
}

const initialState: UserState = {
  group: '',
  name: '',
  email: '',
  uid: 0
}

export const userReducer = (state = initialState, { type, payload }: AnyAction): UserState => {
  switch (type) {
    case actionType.SET_USER: {
      localStorage.setItem('user', JSON.stringify(payload.user))
      return { ...state, ...payload.user }
    }
    case actionType.RESET_USER: {
      localStorage.removeItem('user')
      return { ...state, ...initialState }
    }
    default:
      return state
  }
}
