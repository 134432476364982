import React, { useEffect } from "react";
import "./index.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Result } from "antd";
import Login from "./pages/Login";
import { PrivateRoute } from "./components/PrivateRout";
import MainNav from "./components/main-nav/main-nav";
import Logout from "./components/Logout";
import Thread from "./pages/Thread";
import Reports from "./pages/Reports";
import {
  getAppIsInitialize,
} from "./redux/selectors";
import {
  appInitialize,
  setFeedFilter,
  setUser,
} from "./redux/actions";
import { useAppDispatch } from "./redux/store/hooks";
import Main from "./pages/Main";
import PostDetail from "./pages/PostDetail";
import Audience from "./pages/Audience";
import Sources from "./pages/Sources";

export const App = () => {
  const isInitialize = useSelector(getAppIsInitialize);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isInitialize) {
      dispatch(appInitialize());
    }
  }, [isInitialize]);

  const currentUser = localStorage.getItem("user");
  const currentFeedFilter = localStorage.getItem("feedFilter");


    if (currentUser) {
      dispatch(setUser(JSON.parse(currentUser)));
    }
    if (currentFeedFilter) {
      dispatch(setFeedFilter(JSON.parse(currentFeedFilter)));
    }


  const NotFound = () => {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Такой страницы не существует"
        extra={
          <a href="/main">
            <Button type="primary">На главную</Button>
          </a>
        }
      />
    );
  };

  return (
    <Router>
      {window.location.pathname != "/" && <MainNav />}
      {window.location.pathname != "/" && <Logout />}
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/main" element={<Main />} />
            <Route path="/thread" element={<Thread />} />
            <Route path="/audience" element={<Audience />} />
            <Route path="/sources" element={<Sources />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/post/:Id/:owner/:network/:ref" element={<PostDetail />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};
