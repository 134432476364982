import React from "react";

const IconSmi: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="20px"
      height="20px"
      fill="red"
    >
      <linearGradient
        id="ixkjq1m00N1DjPc4dQeiaa"
        x1="31"
        x2="31"
        y1="28.667"
        y2="43.546"
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor="#6dc7ff" />
        <stop offset="1" stopColor="#e6abff" />
      </linearGradient>
      <path
        fill="url(#ixkjq1m00N1DjPc4dQeiaa)"
        d="M34,43h-6c-0.552,0-1-0.448-1-1V30c0-0.552,0.448-1,1-1h6c0.552,0,1,0.448,1,1v12 C35,42.552,34.552,43,34,43z"
      />
      <linearGradient
        id="ixkjq1m00N1DjPc4dQeiab"
        x1="32"
        x2="32"
        y1="9.75"
        y2="54.584"
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor="#1a6dff" />
        <stop offset="1" stopColor="#c822ff" />
      </linearGradient>
      <path
        fill="url(#ixkjq1m00N1DjPc4dQeiab)"
        d="M53.489,19.023c-0.164-0.015-0.326,0.005-0.489,0.005V19H42v-6c0-1.654-1.346-3-3-3H9 c-1.654,0-3,1.346-3,3v36c0,2.757,2.243,5,5,5h34l0,0c0.004,0,0.007,0,0.011,0c0.158,0,0.317-0.008,0.477-0.023 C48.018,53.736,50,51.474,50,48.828V46h6c1.103,0,2-0.897,2-2V24.172C58,21.527,56.019,19.265,53.489,19.023z M42,31h2v10h-2V31z M8,49V13c0-0.551,0.448-1,1-1h30c0.552,0,1,0.449,1,1v36c0,1.09,0.367,2.14,1.013,3H11C9.346,52,8,50.654,8,49z M48,23.997v24.832 c0,1.626-1.188,3.013-2.702,3.158c-0.856,0.085-1.683-0.189-2.315-0.766C42.358,50.653,42,49.843,42,49v-6h2c1.103,0,2-0.897,2-2V31 c0-1.103-0.897-2-2-2h-2v-2h4v-2h-4v-4h7.012C48.367,21.86,48,22.908,48,23.997z M56,44h-6v-5h2v-2h-2v-2h2v-2h-2v-2h2v-2h-2v-2h2 v-2h-2v-1.003c0-0.842,0.357-1.649,0.981-2.217c0.634-0.576,1.448-0.848,2.318-0.765c1.514,0.145,2.7,1.531,2.7,3.157V44z"
      />
      <linearGradient
        id="ixkjq1m00N1DjPc4dQeiac"
        x1="23"
        x2="23"
        y1="9.75"
        y2="54.584"
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor="#1a6dff" />
        <stop offset="1" stopColor="#c822ff" />
      </linearGradient>
      <path fill="url(#ixkjq1m00N1DjPc4dQeiac)" d="M11 21H35V23H11z" />
      <linearGradient
        id="ixkjq1m00N1DjPc4dQeiad"
        x1="23"
        x2="23"
        y1="9.75"
        y2="54.584"
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor="#1a6dff" />
        <stop offset="1" stopColor="#c822ff" />
      </linearGradient>
      <path fill="url(#ixkjq1m00N1DjPc4dQeiad)" d="M11 25H35V27H11z" />
      <linearGradient
        id="ixkjq1m00N1DjPc4dQeiae"
        x1="17.5"
        x2="17.5"
        y1="9.75"
        y2="54.584"
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor="#1a6dff" />
        <stop offset="1" stopColor="#c822ff" />
      </linearGradient>
      <path fill="url(#ixkjq1m00N1DjPc4dQeiae)" d="M11 29H24V31H11z" />
      <linearGradient
        id="ixkjq1m00N1DjPc4dQeiaf"
        x1="17.5"
        x2="17.5"
        y1="9.75"
        y2="54.584"
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor="#1a6dff" />
        <stop offset="1" stopColor="#c822ff" />
      </linearGradient>
      <path fill="url(#ixkjq1m00N1DjPc4dQeiaf)" d="M11 33H24V35H11z" />
      <linearGradient
        id="ixkjq1m00N1DjPc4dQeiag"
        x1="17.5"
        x2="17.5"
        y1="9.75"
        y2="54.584"
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor="#1a6dff" />
        <stop offset="1" stopColor="#c822ff" />
      </linearGradient>
      <path fill="url(#ixkjq1m00N1DjPc4dQeiag)" d="M11 37H24V39H11z" />
      <linearGradient
        id="ixkjq1m00N1DjPc4dQeiah"
        x1="17.5"
        x2="17.5"
        y1="9.75"
        y2="54.584"
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor="#1a6dff" />
        <stop offset="1" stopColor="#c822ff" />
      </linearGradient>
      <path fill="url(#ixkjq1m00N1DjPc4dQeiah)" d="M11 41H24V43H11z" />
    </svg>
  );
};

export default IconSmi;
