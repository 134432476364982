import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Pagination,
  Radio,
  RadioChangeEvent,
  Select,
  Switch,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TonalStatistic from "../components/TonalStatistic";
import {
  addStopSource,
  backRemovedPost,
  changePostPage,
  feedFilterClear,
  feedFilterSubmit,
  fetchPosts,
  fetchStopPost,
  logoutAuth,
  removePost,
  removeRefPost,
  removeThreadPost,
  setReferences,
} from "../redux/actions";
import {
  getPostCount,
  getPostIsLoading,
  getPostList,
  getPostPageNumber,
  getPostPageSize,
  getProduct,
  getReferencesFilter,
  getReferences,
  getThread,
} from "../redux/selectors";
import { useAppDispatch } from "../redux/store/hooks";
import locale from "antd/es/date-picker/locale/ru_RU";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import {
  CloseOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Post from "../components/post";
import IconUndo from "../components/icons/undo";
import IconChecked from "../components/icons/checked";
import { referencesService } from "../api";
import FeedStatistics from "../components/feed-statistics/feed-statistics";

const Thread = () => {
  const [value, setValue] = useState("today");
  const { RangePicker } = DatePicker;
  const feedFilter = useSelector(getFeedFilter);
  const [tonality, setTonality] = useState(feedFilter.tonal);
  const [tonal, setTonal] = useState();
  const [repostOption, setRepostOption] = useState(feedFilter.repostoption);
  const [friendly, setFriendly] = useState(feedFilter.friendly);
  const [sorting, setSorting] = useState(feedFilter.sort);
  const [switchValue, setSwitchValue] = useState(false);
  const [checkedPosts, setCheckedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("post");
  const [competitors, setCompetitors] = useState([]);
  const referencesFilter = useSelector(getReferencesFilter);
  const references = useSelector(getReferences);
  const [client, setClient] = useState({ id: referencesFilter });
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const isLoading = useSelector(getPostIsLoading);
  const postList = useSelector(getPostList);
  const postCount = useSelector(getPostCount);
  const pageNumber = useSelector(getPostPageNumber);
  const pageSize = useSelector(getPostPageSize);
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];
  const [source, setSource] = useState({
    smi: feedFilter.smi,
    vk: feedFilter.vk,
    tw: feedFilter.tw,
    ig: feedFilter.ig,
    tg: feedFilter.tg,
    yt: feedFilter.yt,
    ok: feedFilter.ok,
    fb: feedFilter.fb,
  });
  const [sourceChange, setSourceChange] = useState({
    smi: feedFilter.smi,
    vk: feedFilter.vk,
    tw: feedFilter.tw,
    ig: feedFilter.ig,
    tg: feedFilter.tg,
    yt: feedFilter.yt,
    ok: feedFilter.ok,
    fb: feedFilter.fb,
  });
  const [sourceOptions, setSourceOptions] = useState([
    "tg",
    "vk",
    "ok",
    "fb",
    "tw",
    "yt",
    "ig",
    "smi",
  ]);
  const thread = useSelector(getThread);
  const product = useSelector(getProduct);
  const dispatch = useAppDispatch();

  useEffect(() => {
    onClear();
  }, []);

  useEffect(() => {
    setLoading(true);
      referencesService
        .loadClientProducts()
        .then((resp) => {
          setClient(resp.find((it: any) => it.id == product)?.client);
          setCompetitors(resp.find((it: any) => it.id == product)?.competitor);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(logoutAuth());
        });
  }, [product]);

  useEffect(() => {
    dispatch(
      feedFilterSubmit({
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        smi: source.smi,
        vk: source.vk,
        tw: source.tw,
        fb: source.fb,
        ig: source.ig,
        tg: source.tg,
        yt: source.yt,
        ok: source.ok,
        tonal: tonality,
        friendly: friendly,
        sorting: sorting,
        profile_id: [],
        repostoption: repostOption,
      })
    );
    if (thread && tab == "post") dispatch(fetchPosts());
    if (thread && tab == "stop") dispatch(fetchStopPost());
  }, [
    source,
    dateString,
    sorting,
    tonality,
    thread,
    repostOption,
    product,
    tab,
  ]);

  const onClear = (): void => {
    setDateString([
      new Date().toJSON().split("T")[0],
      new Date().toJSON().split("T")[0],
    ]);
    setSource({
      smi: true,
      vk: true,
      tw: true,
      fb: true,
      ig: true,
      tg: true,
      yt: true,
      ok: true,
    });
    setValue("today");
    setSourceOptions(["tg", "vk", "ok", "fb", "tw", "yt", "ig", "smi"]);
    setTonality("");
    setFriendly("");
    setSorting(null);
    setRepostOption("whatever");
    setSwitchValue(false);
    dispatch(feedFilterClear());
    dispatch(changePostPage(1));
  };

  const onChangeRepost = (checked: boolean) => {
    setRepostOption(checked ? "containrepost" : "whatever");
    setSwitchValue(checked);
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);

    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
    dispatch(changePostPage(1));
  };

  const handleChangeSort = (value: string) => {
    setSorting(value);
    dispatch(changePostPage(1));
  };

  const handleChangeTonal = (value: string) => {
    setTonality(value);
    dispatch(changePostPage(1));
  };

  const handleChangeSource = (value: string[]) => {
    for (let key in source) {
      value.indexOf(key) != -1
        ? setSourceChange((prevState) => ({
            ...prevState,
            [key]: true,
          }))
        : setSourceChange((prevState) => ({
            ...prevState,
            [key]: false,
          }));
    }
    setSourceOptions(value);
    //dispatch(changePostPage(1));
  };

  const changeSources = () => {
    setSource(sourceChange);
    dispatch(changePostPage(1));
  };

  const onCheck: any = (target: any, post: any) => {
    let checkedPostsArr: any = [...checkedPosts];
    if (target == true) checkedPostsArr.push(post);
    else if (target == false) {
      const filteredNumbers = checkedPostsArr.filter(
        (it: any) => it.id !== post.id
      );
      checkedPostsArr = filteredNumbers;
    }
    setCheckedPosts(checkedPostsArr);
  };

  const selectAll = () => {
    let checkedPostsArr: any = [];
    postList?.forEach((post) => checkedPostsArr.push(post));
    setCheckedPosts(checkedPostsArr);
  };

  const deleteAll = () => {
    if (tab == "post")
      checkedPosts?.forEach((post: any) => {
        dispatch(
          removeRefPost(post.id, post.ownerId, post.network.id, references[0])
        );
        setCheckedPosts([]);
      });
    if (tab == "stop")
      checkedPosts?.forEach((post: any) => {
        dispatch(
          backRemovedPost(post.id, post.ownerId, post.network.id, references[0])
        );
        setCheckedPosts([]);
        //dispatch(removeThreadPost(post.id, post.ownerId, post.network.id))
      });
    if (thread && tab == "post") dispatch(fetchPosts());
    if (thread && tab == "stop")
      setTimeout(() => dispatch(fetchStopPost()), 1000);
  };

  const items: TabsProps["items"] = [
    {
      key: "post",
      label: "Посты",
      //children: 'Content of Tab Pane 1',
    },
    {
      key: "stop",
      label: "Исключенные",
      //children: 'Content of Tab Pane 2',
    },
  ];

  const onChangeTab = (key: string) => {
    setTab(key);
  };

  const changeTonal = (value:any) => {
    console.log(value)
    setTonal(value)
    //if (thread && tab == "post") dispatch(fetchPosts());
    //if (thread && tab == "stop") dispatch(fetchStopPost());
 }

  return (
    <div className="App">
      <h1>Лента постов</h1>
      {!isLoading ? (
        <div className="btn-group">
          <Button
            type={references == client?.id ? "primary" : "default"}
            onClick={() => {
              dispatch(setReferences([client?.id]));
              if (thread) dispatch(fetchPosts());
            }}
          >
            Моя компания
          </Button>
          {competitors?.map((competitor: any, id) => (
            <Button
              key={id}
              type={references == competitor?.id ? "primary" : "default"}
              onClick={() => {
                dispatch(setReferences([competitor?.id]));
                if (thread) dispatch(fetchPosts());
              }}
            >
              {competitor?.title}
            </Button>
          ))}
        </div>
      ) : (
        <div className="feed-posts-loader">
          <LoadingOutlined />
        </div>
      )}
      <TonalStatistic tonal={tonal}/>
      <Tabs
        className="tabs-group"
        defaultActiveKey="1"
        items={items}
        onChange={onChangeTab}
      />
      <div className="white-block">
        {tab == "post" && (
          <>
            <FeedStatistics referencesFilter={referencesFilter} />
            <div className="flex-space">
              <div className="flex">
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        activeBorderColor: "#7856FE",
                      },
                    },
                  }}
                >
                  <Select
                    style={{ width: 195 }}
                    allowClear={false}
                    onChange={handleChangeSort}
                    disabled={isLoading}
                    options={[
                      { value: "dateAsc", label: "Сначала старые" },
                      { value: "dateDesc", label: "Сначала новые" },
                      { value: "viewDesc", label: "Больше просмотров" },
                      { value: "viewAsc", label: "Меньше просмотров" },
                      { value: "comDesc", label: "Больше комментариев" },
                      { value: "comAsc", label: "Меньше комментариев" },
                      { value: "repostDesc", label: "Больше репостов" },
                      { value: "repostAsc", label: "Меньше репостов" },
                      { value: "likeDesc", label: "Больше лайков" },
                      { value: "likeAsc", label: "Меньше лайков" },
                    ]}
                    placeholder="Сортировать по"
                  />
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        activeBorderColor: "#7856FE",
                      },
                    },
                  }}
                >
                  <Select
                    value={sourceOptions}
                    mode="multiple"
                    allowClear={true}
                    style={{ width: 200 }}
                    maxTagCount={"responsive"}
                    disabled={isLoading}
                    options={[
                      { value: "tg", label: "Telegram" },
                      { value: "vk", label: "ВКонтакте" },
                      { value: "ok", label: "Одноклассники" },
                      { value: "fb", label: "Facebook" },
                      { value: "tw", label: "Twitter" },
                      //{ value: "dz", label: "Дзен" },
                      { value: "yt", label: "Youtube" },
                      { value: "ig", label: "Instagram" },
                      { value: "smi", label: "СМИ" },
                    ]}
                    placeholder="Тип источника"
                    dropdownRender={(menu) => (
                      <>
                        {menu}

                        <Button type="text" onClick={changeSources}>
                          Применить
                        </Button>
                      </>
                    )}
                    onChange={handleChangeSource}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        color={"#7856FE"}
                        overlayStyle={{ pointerEvents: "none" }}
                        title={omittedValues
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>Выбранные источники</span>
                      </Tooltip>
                    )}
                  />
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        activeBorderColor: "#7856FE",
                      },
                    },
                  }}
                >
                  <Select
                    style={{ width: 195 }}
                    allowClear={false}
                    onChange={handleChangeTonal}
                    disabled={isLoading}
                    options={[
                      { value: null, label: "Все" },
                      { value: 1, label: "Позитивные" },
                      { value: -1, label: "Негативные" },
                      { value: 0, label: "Нейтральные" },
                    ]}
                    placeholder="Тональность"
                  />
                </ConfigProvider>
                <div className="left-border">
                  <p className={isLoading ? "grey" : ""}>Наличие репоста</p>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#D8CCFB",
                        colorPrimaryHover: "#D8CCFB",
                        colorTextTertiary: "rgba(0, 0, 0, 0.2)",
                        colorTextQuaternary: "rgba(0, 0, 0, 0.1)",
                        fontSize: 10,
                      },
                      components: {
                        Switch: {
                          handleSize: 22,
                          trackPadding: -2,
                        },
                      },
                    }}
                  >
                    <Switch
                      value={switchValue}
                      onChange={onChangeRepost}
                      disabled={isLoading}
                    />
                  </ConfigProvider>
                </div>
              </div>

              <div className="flex">
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        buttonSolidCheckedColor: "#7856FE",
                        buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                        colorPrimary: "rgba(0, 0, 0, 0.1)",
                        buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                      },
                    },
                  }}
                >
                  <Radio.Group
                    options={options}
                    onChange={onChange}
                    value={value}
                    optionType="button"
                    buttonStyle="solid"
                    disabled={isLoading}
                  />
                </ConfigProvider>

                {value == "period" && (
                  <RangePicker
                    format={"DD-MM-YYYY"}
                    onChange={onPeriodChange}
                    style={{ width: "278px" }}
                    className="choose-period"
                    allowClear={false}
                    locale={locale}
                  />
                )}
              </div>
            </div>
          </>
        )}

        <div className="feed-posts">
          {isLoading && (
            <div className="feed-posts-loader">
              <LoadingOutlined />
            </div>
          )}
          {!isLoading && postCount > 0 && (
            <>
              <div className="flex">
                <div className="grey">
                  Всего постов: {(+postCount).toLocaleString()}
                </div>
                {tab == "post" && (
                  <p onClick={onClear} className="flex clear">
                    Очистить фильтры <CloseOutlined className="info" />
                  </p>
                )}
              </div>
              <div className="post-list">
                {postList?.map((item, id) => (
                  <div className="flex" key={id}>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#7856FE",
                          colorPrimaryHover: "rgba(0, 0, 0, 0.1)",
                          controlInteractiveSize: 25,
                        },
                      }}
                    >
                      <Checkbox
                        checked={
                          checkedPosts.findIndex(
                            (it: any) => it.id == item.id
                          ) != -1
                        }
                        onChange={(e) => onCheck(e.target.checked, item)}
                      ></Checkbox>
                    </ConfigProvider>
                    <Post key={item.id} post={item} changeTonal={changeTonal}/>
                  </div>
                ))}
              </div>
              {postCount > 0 ? (
                <div className="feed-posts-pagination">
                  <Pagination
                    size="small"
                    onChange={(value) => {
                      dispatch(changePostPage(value));
                      if (thread) dispatch(fetchPosts());
                    }}
                    total={postCount}
                    pageSize={pageSize}
                    current={pageNumber}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                  />
                </div>
              ) : null}
            </>
          )}
          {!isLoading && postCount == 0 && (
            <div className="flex">
              <div className="feed-posts__empty">Постов пока нет</div>
              <p onClick={onClear}>
                Очистить фильтры <CloseOutlined className="info" />
              </p>
            </div>
          )}
        </div>
      </div>
      {checkedPosts.length > 0 && (
        <div className="fixed-block flex-space">
          <div className="flex">
            <a onClick={selectAll} className="flex btn-cursor">
              <IconChecked />
              Выбрать все
            </a>
            <a onClick={() => setCheckedPosts([])} className="flex btn-cursor">
              <IconUndo />
              Сбросить
            </a>
          </div>
          <div className="flex">
            <div className="grey">Постов выбрано: {checkedPosts.length}</div>
            <div className="red-bg red btn-cursor" onClick={deleteAll}>
              <DeleteOutlined />{" "}
              {tab == "post" ? "Исключить" : "Вернуть в ленту"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Thread;
