import React from "react";

const IconReaction: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.391 2.51882C11.5274 3.65525 11.5274 5.49776 10.391 6.63418C9.25459 7.77061 7.41208 7.77061 6.27566 6.63418C5.13923 5.49776 5.13923 3.65525 6.27566 2.51882C7.41208 1.3824 9.25459 1.3824 10.391 2.51882"
        stroke="#323232"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9692 10.6635C10.8867 10.231 9.61675 9.99268 8.33341 9.99268C4.96008 9.99268 1.66675 11.6318 1.66675 14.1527V14.986C1.66675 15.446 2.04008 15.8193 2.50008 15.8193H10.3442"
        stroke="#323232"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 18.3333C11.8658 18.3333 10 16.4683 10 14.1667C10 11.9133 11.9167 9.9975 14.17 10C16.47 10.0017 18.3333 11.8667 18.3333 14.1667C18.3333 16.4675 16.4683 18.3333 14.1667 18.3333"
        stroke="#323232"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7083 15.6252L15.6249 12.7085"
        stroke="#323232"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7572 15.5229C15.8223 15.588 15.8223 15.6936 15.7572 15.7586C15.6921 15.8237 15.5866 15.8237 15.5215 15.7586C15.4564 15.6936 15.4564 15.588 15.5215 15.5229C15.5866 15.4578 15.6921 15.4578 15.7572 15.5229"
        stroke="#323232"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8128 12.5747C12.8779 12.6398 12.8779 12.7453 12.8128 12.8104C12.7478 12.8755 12.6422 12.8755 12.5771 12.8104C12.512 12.7453 12.512 12.6398 12.5771 12.5747C12.6422 12.5096 12.7478 12.5096 12.8128 12.5747"
        stroke="#323232"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconReaction;
