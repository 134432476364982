import {
  FallOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
} from "antd";
import React, { useEffect, useState } from "react";
import LineChart from "./LineChart";
import locale from "antd/es/date-picker/locale/ru_RU";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { useSelector } from "react-redux";
import { getProduct, getThread } from "../redux/selectors";
import { summaryService } from "../api";

const Authors: React.FC = () => {
  const [value, setValue] = useState("month");
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false);
  const feedFilter = useSelector(getFeedFilter);
  const [loading, setLoading] = useState(true);
  const thread = useSelector(getThread);
  const product = useSelector(getProduct);
  const [mainNumber, setMainNumber] = useState(0);
  const start = new Date();
  start.setDate(start.getDate() - 29);
  const [previousMentions, setPreviousMentions] = useState(0);
  const [dateString, setDateString] = useState([
    start.toJSON().split("T")[0],
    new Date().toJSON().split("T")[0],
  ]);
  const [graphData, setGraphData] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];

  useEffect(() => {
    setLoading(true);
    if (thread && dateString) {
      let params = {
        thread_id: +thread.id,
        product_id: product,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
      };

      summaryService
        .loadAuthors(params)
        .then((resp) => {
          setGraphData(resp.slice(1, resp.length));
          let sum = 0;
          resp.slice(1, resp.length).forEach(function (num: any) {
            sum += num[1];
          });
          setMainNumber(sum);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString, product]);

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);

    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  return (
    <div className="white-block">
      <div className="flex-space">
        <div>
          <p className="block-title">
            Уникальные авторы
            <InfoCircleOutlined className="info" onClick={showDrawer} />
          </p>
        </div>
        <div className="flex">
          <ConfigProvider
            theme={{
              components: {
                Radio: {
                  buttonSolidCheckedColor: "#7856FE",
                  buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                  colorPrimary: "rgba(0, 0, 0, 0.1)",
                  buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                },
              },
            }}
          >
            <Radio.Group
              options={options}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />
          </ConfigProvider>

          {value == "period" && (
            <RangePicker
              format={"DD-MM-YYYY"}
              onChange={onPeriodChange}
              style={{ width: "250px" }}
              className="choose-period"
              allowClear={false}
              locale={locale}
            />
          )}
        </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader">
          <LoadingOutlined />
        </div>
      ) : (
        <div>
          <div className="flex">
            <div className="title-big">{mainNumber.toLocaleString()}</div>
            {/*previousMentions > 0 ? (
              <div className="green green-bg">
                <RiseOutlined /> +{previousMentions?.toFixed(2)}%
              </div>
            ) : (
              <div className="red red-bg">
                <FallOutlined /> {previousMentions?.toFixed(2)}%
              </div>
            )*/}
          </div>
          <LineChart graphData={graphData} title={"Авторов"} />
        </div>
      )}

      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <p className="purple-border">
          График иллюстрирует количество уникальных
          источников, разместивших публикации о компании вне зависимости от
          количества публикаций в отдельно взятом источнике
        </p>
        <p className="title-small">Как интерпретировать данные</p>
        <ol>
          <li>
            Временные интервалы: Вы можете выбрать различные временные интервалы
            для анализа динамики.
          </li>
        </ol>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default Authors;
