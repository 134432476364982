import "../App.css";
import ChartMap from "../components/ChartMap";
import PostHorizontal from "../components/PostHorizontal";
import ThemesHorizontal from "../components/ThemesHorizontal";
import SmiHorizontal from "../components/SmiHorizontal";
import Authors from "../components/Authors";
import CitiesTable from "../components/CitiesTable";
import StopSourcesList from "../components/StopSourcesList";
import { useAppDispatch } from "../redux/store/hooks";
import { resetFeedFilter } from "../redux/actions";

function Sources() {
  const dispatch = useAppDispatch();
  dispatch(resetFeedFilter())
  
  return (
    <div className="App">
      <h1>Источники</h1>
      {/*<ChartMap />*/}
      <Authors />
      <div className="grid2 main-block">
        <PostHorizontal />
        <SmiHorizontal />
        <ThemesHorizontal />
        <CitiesTable/>
      </div>
      <StopSourcesList/>
    </div>
  );
}

export default Sources;
