import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getReferencesFilter, getThread } from "../redux/selectors";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import locale from "antd/es/date-picker/locale/ru_RU";
import { summaryService } from "../api";
import HorisontalChart from "./HorizontalChart";
import { findSource } from "../scripts/utils";

const PostsHorizontal: React.FC = () => {
  const thread = useSelector(getThread);
  const feedFilter = useSelector(getFeedFilter);
  const [value, setValue] = useState("today");
  const [loading, setLoading] = useState(true);
  const { RangePicker } = DatePicker;
  const [total, setTotal] = useState(0);
  const [dataArr, setDataArr] = useState([]);
  const [labelsArr, setLabelsArr] = useState([]);
  const referencesFilter = useSelector(getReferencesFilter);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (thread) {
      let params = {
        thread_id: +thread.id,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        referenceFilter: referencesFilter,
      };
      setLoading(true);
      summaryService
        .loadTrustDailyPosts(params)
        .then((resp) => {
          let arr: any = [];
          for (let key in resp) {
            let data: any = {};
            let result = resp[key].total.reduce(function (
              sum: any,
              current: any
            ) {
              return sum + current[1];
            },
            0);
            if (key != "total" && key != "gs") {
              data["count"] = result;
              data["key"] = key;
              arr.push(data);
            } else if (key == "total") setTotal(result);
          }
          arr.sort((a: any, b: any) => b.count - a.count);
          setDataArr(arr.map((it: any) => it.count));
          setLabelsArr(arr.map((it: any) => findSource(it.key)));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString, referencesFilter]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);

    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  return (
    <div className="white-block-small">
      <div className="">
        <p className="block-title">
          По количеству публикаций в Соцсетях
          <InfoCircleOutlined className="info" onClick={showDrawer} />
        </p>
        <div className="flex">
          <ConfigProvider
            theme={{
              components: {
                Radio: {
                  buttonSolidCheckedColor: "#7856FE",
                  buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                  colorPrimary: "rgba(0, 0, 0, 0.1)",
                  buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                },
              },
            }}
          >
            <Radio.Group
              options={options}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />
          </ConfigProvider>
          {value == "period" && (
            <RangePicker
              format={"DD-MM-YYYY"}
              onChange={onPeriodChange}
              style={{ width: "278px" }}
              className="choose-period"
              allowClear={false}
              locale={locale}
            />
          )}
        </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader" style={{ height: "300px" }}>
          <LoadingOutlined />
        </div>
      ) : (
        <HorisontalChart labelsArr={labelsArr} dataArr={dataArr} />
      )}
      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <p className="purple-border">
          График иллюстрирует суммарное количество публикаций с упоминаниями
          компании в каждой социальной сети за выбранный период.
        </p>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default PostsHorizontal;
