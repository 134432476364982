import "../App.css";
import AudienceDonuts from "../components/AudienceDonuts";
import CitiesTable from "../components/CitiesTable";
import CityDonut from "../components/CityDonut";
import ViewersTable from "../components/ViewersTable";
import { resetFeedFilter } from "../redux/actions";
import { useAppDispatch } from "../redux/store/hooks";

function Audience() {
  const dispatch = useAppDispatch();
  dispatch(resetFeedFilter())
  
  return (
    <div className="App">
      <h1>Аудитория</h1>
      {/*<ChartMap />*/}
      <AudienceDonuts />
      <div className="grid1-2">
        <CityDonut />
        <ViewersTable/>
      </div>
    </div>
  );
}

export default Audience;
