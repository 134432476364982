import React from "react";

const IconFileWord: React.FC = () => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8056 9.59611H22.4029C21.5189 9.59611 20.8022 8.87946 20.8022 7.99544V1.59277"
        stroke="#2D92D4"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.59424"
        y="9.5957"
        width="16.0067"
        height="16.0067"
        rx="4.8"
        stroke="#2D92D4"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99609 14.7988L6.79643 20.4012H7.32305L9.59759 15.5992L11.8721 20.4012H12.3988L13.1991 14.7988"
        stroke="#2D92D4"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.396 9.59611V4.79411C6.396 3.02606 7.82928 1.59277 9.59733 1.59277H21.0773C21.9263 1.59277 22.7404 1.93036 23.3407 2.53076L27.8673 7.05745C28.4677 7.65768 28.8053 8.47182 28.8053 9.32079V27.2034C28.8053 28.9715 27.372 30.4048 25.604 30.4048H9.59733C7.82928 30.4048 6.396 28.9715 6.396 27.2034V25.6028"
        stroke="#2D92D4"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconFileWord;
