import React from "react";

const IconFavorite: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1395 4.38444C10.9588 3.62707 12.0194 3.18284 13.1338 3.13027L13.3574 3.125C14.6276 3.125 15.8447 3.6348 16.7486 4.55354L16.9009 4.71808C18.5915 6.63193 18.5255 9.53388 16.7356 11.3732L12.2419 15.936C11.6506 16.5367 10.843 16.875 10.0002 16.875C9.15733 16.875 8.34974 16.5367 7.75859 15.9362L3.26209 11.3705C1.41285 9.47012 1.41285 6.44289 3.27646 4.52825L3.44706 4.36427C4.31979 3.56758 5.45959 3.125 6.64293 3.125C7.90329 3.125 9.11134 3.62689 10.0004 4.51891L10.1395 4.38444ZM15.8449 5.41694C15.1887 4.75037 14.2926 4.375 13.3573 4.375C12.422 4.375 11.5259 4.75037 10.8696 5.41705L10.4454 5.84782C10.2006 6.09641 9.79962 6.09641 9.55479 5.84781L9.1304 5.41687C8.4743 4.75034 7.57816 4.375 6.64289 4.375C5.77159 4.375 4.93233 4.70088 4.30195 5.27604L4.1579 5.41431C2.78087 6.82939 2.78087 9.08362 4.15528 10.496L8.64927 15.0592C9.00557 15.4211 9.49223 15.625 10.0001 15.625C10.508 15.625 10.9947 15.4211 11.3511 15.059L15.8424 10.4988C17.1732 9.13122 17.2223 6.97005 15.9742 5.55683L15.8449 5.41694Z"
        fill="#1D1929"
      />
    </svg>
  );
};

export default IconFavorite;
