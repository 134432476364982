import React from "react";
import { Bar } from "react-chartjs-2";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Colors,
  LinearScale,
  Tooltip,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

type Props = {
  labelsArr: any;
  dataArr: any;
};

const HorisontalChart: React.FC<Props> = ({ labelsArr, dataArr }: Props) => {
  Chart.register(
    Colors,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    ArcElement,
    ChartDataLabels
  );

  const arrSource = ["ВКонтакте", "Телеграм", "ОК", "Facebook", "Youtube"];

  const data: any = {
    labels: labelsArr,
    datasets: [
      {
        label: "# of Votes",
        data: dataArr,
        backgroundColor: "#B293FF",
        borderColor: "#B293FF",
        borderWidth: 0,
        borderRadius: 5,
        borderSkipped: false,
        datalabels: {
          align: "end",
          anchor: "end",
          font: {
            weight: "bold",
            size: 14,
          },
        },
      },
    ],
    options: {
      plugins: {
        datalabels: {
          align: function (context: any) {
            var index = context.dataIndex;
            var datasets = context.chart.data.datasets;
            var v0 = datasets[0].data[index];
            var v1 = datasets[1].data[index];
            var invert = v0 - v1 > 0;
            return context.datasetIndex === 0
              ? invert
                ? "end"
                : "start"
              : invert
              ? "start"
              : "end";
          },
          backgroundColor: function (context: any) {
            return context.dataset.borderColor;
          },
          borderRadius: 4,
          color: "white",
          font: {
            weight: "bold",
          },
          offset: 8,
          padding: 6,
          formatter: Math.round,
        },
      },
    },
  };

  return (
    <div>
      <Bar
        data={data}
        options={{
          indexAxis: "y" as const,
          scales: {
            x: {
              display: false,
              clip: false,
              grid: { display: false, lineWidth: 0 },
              border: { display: false, width: 0 },
              offset: true,
            },
            y: {
              display: true,
              clip: false,
              grid: { display: false, lineWidth: 0 },
              border: { display: false, width: 0 },
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
              position: "nearest",
            },
          },
        }}
        plugins={[
          {
            id: "drawBar",
            beforeDraw(chart) {
              const { config } = chart;
            },
          },
        ]}
      />
    </div>
  );
};

export default HorisontalChart;
