import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../App.css";
import PostFull from "../components/postFull";

function PostDetail() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <h1 style={{cursor:"pointer"}} onClick={() => navigate("/thread")}>
        <LeftOutlined />
        Назад
      </h1>
      <PostFull />
    </div>
  );
}

export default PostDetail;
