import React from "react";
import IconDzen from "../components/icons/dzen";
import IconFacebook from "../components/icons/facebook";
import IconInstagram from "../components/icons/instagram";
import IconOk from "../components/icons/ok";
import IconSmi from "../components/icons/smi";
import IconTelegram from "../components/icons/telegram";
import IconTwitter from "../components/icons/twitter";
import IconVk from "../components/icons/vk";
import IconYoutube from "../components/icons/youtube";

type Props = {
  networkName: string;
};

const NetworkIcon: React.FC<Props> = ({ networkName }: Props) => {
  switch (networkName) {
    case "vk":
      return <IconVk />;
    case "ok":
      return <IconOk />;
    case "tg":
      return <IconTelegram />;
    case "ig":
      return <IconInstagram />;
    case "fb":
      return <IconFacebook />;
    case "yt":
      return <IconYoutube />;
    case "tw":
      return <IconTwitter />;
    case "dz":
      return <IconDzen />;
    case "gs":
      return <IconSmi />;
    default:
      return null;
  }
};

export default NetworkIcon;
