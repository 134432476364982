import React from "react";

const IconTelegram: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_281_7549)">
        <path
          d="M8.00623 16.0125C12.428 16.0125 16.0125 12.428 16.0125 8.00623C16.0125 3.58451 12.428 0 8.00623 0C3.58451 0 0 3.58451 0 8.00623C0 12.428 3.58451 16.0125 8.00623 16.0125Z"
          fill="url(#paint0_linear_281_7549)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.61665 7.91894C5.94869 6.89634 7.50753 6.23539 8.29319 5.91115C10.513 4.98831 10.9744 4.82619 11.2737 4.81372C11.3361 4.81372 11.4857 4.82619 11.5855 4.90102C11.6603 4.96337 11.6852 5.05067 11.6977 5.11302C11.7102 5.17537 11.7227 5.31255 11.7102 5.42479C11.5855 6.68434 11.0742 9.76462 10.7998 11.1738C10.6876 11.7724 10.4631 11.9719 10.2511 11.9969C9.78969 12.0343 9.42803 11.6851 8.97909 11.3983C8.28072 10.9369 7.88166 10.65 7.19577 10.2011C6.41011 9.67732 6.92141 9.39049 7.37036 8.92908C7.48259 8.80437 9.54027 6.94622 9.57768 6.77163C9.57768 6.74669 9.59015 6.67187 9.54027 6.63445C9.49039 6.59704 9.42803 6.60951 9.37815 6.62198C9.30333 6.63445 8.18096 7.3827 5.99857 8.85425C5.67433 9.07872 5.3875 9.17849 5.12562 9.17849C4.83879 9.17849 4.29008 9.01637 3.87854 8.87919C3.37971 8.71707 2.98064 8.62978 3.01806 8.35542C3.043 8.21824 3.24253 8.06859 3.61665 7.91894Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_281_7549"
          x1="0"
          y1="8.00043"
          x2="16.0009"
          y2="8.00043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
        <clipPath id="clip0_281_7549">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconTelegram;
