import React from "react";

const IconComment: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.45917 13.6633C2.85083 12.58 2.5 11.3317 2.5 10C2.5 5.8575 5.8575 2.5 10 2.5C14.1425 2.5 17.5 5.8575 17.5 10C17.5 14.1425 14.1425 17.5 10 17.5C8.66833 17.5 7.42 17.1492 6.33667 16.5408L2.5 17.5L3.45917 13.6633Z"
        stroke="#1D1929"
        strokeWidth="1.3235"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconComment;
