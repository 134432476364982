import { configureStore } from '@reduxjs/toolkit'
import { appReducer as app, AppState } from '../reducers/app.reducer'
import { authReducer as auth, AuthState } from '../reducers/auth.reducer'
import { userReducer as user, UserState } from '../reducers/user.reducer'
import { postsReducer as posts, PostsState } from '../reducers/posts.reducer'
import { feedFilterReducer as feedFilter, FeedFilterState } from '../reducers/feed-filter.reducer'

export const store = configureStore({
  reducer: {
    app,
    auth,
    user,
    posts,
    feedFilter
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
