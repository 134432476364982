import { AnyAction } from 'redux'
import * as actionType from './types'
import { fetchPosts } from '../actions/posts.actions'
import { fetchFeedStatistics } from '../actions'

export const showFeedFilter = (): AnyAction => ({ type: actionType.SHOW_FEED_FILTER })
export const hideFeedFilter = (): AnyAction => ({ type: actionType.HIDE_FEED_FILTER })
export const setFeedFilter = (filterValues:any): AnyAction => ({ type: actionType.SET_FEED_FILTER, payload: filterValues })
export const resetFeedFilter = (): AnyAction => ({ type: actionType.RESET_FEED_FILTER })
export const removeFeedFilter = (): AnyAction => ({ type: actionType.REMOVE_FEED_FILTER })

export const feedFilterSubmit = (filterValues:any) => (dispatch:any, getState:any) => {
  const { app } = getState()
  dispatch(setFeedFilter(filterValues))
  //dispatch(fetchPosts())
  //dispatch(fetchFeedStatistics(app.referencesFilter, sourceType))
}

export const feedFilterClear = () => (dispatch:any, getState:any) => {
  const { app } = getState()
  dispatch(resetFeedFilter())
  //dispatch(fetchPosts())
  //dispatch(fetchFeedStatistics(app.referencesFilter, sourceType))
}
