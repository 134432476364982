import React from "react";
import Activity from "./Activity";
import AgeDonut from "./AgeDonut";
import Coverage from "./Coverage";
import SexDonut from "./SexDonut";

const AudienceDonuts: React.FC = () => {
  return (
    <div className="grid3 main-block">
      <SexDonut />
      <AgeDonut />
      <Coverage title={"activity"} />
    </div>
  );
};

export default AudienceDonuts;
