import React from "react";

const IconPeople: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66675 15.8333C1.66675 14 3.16675 12.5 5.00008 12.5H8.33341C10.1667 12.5 11.6667 14 11.6667 15.8333"
        stroke="#1D1929"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74998 5.00029C9.91665 6.16696 9.91665 8.00029 8.74998 9.08362C7.58331 10.167 5.74998 10.2503 4.66665 9.08362C3.58331 7.91696 3.49998 6.16696 4.58331 5.00029C5.66665 3.83362 7.58331 3.91696 8.74998 5.00029"
        stroke="#1D1929"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 11.6665H15.8333C17.2499 11.6665 18.3333 12.7498 18.3333 14.1665"
        stroke="#1D1929"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0833 5.5835C16.9167 6.41683 16.9167 7.75016 16.0833 8.50016C15.25 9.25016 13.9167 9.3335 13.1667 8.50016C12.4167 7.66683 12.3333 6.3335 13.1667 5.5835C13.9167 4.8335 15.25 4.8335 16.0833 5.5835"
        stroke="#1D1929"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPeople;
