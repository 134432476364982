import { InvalidCredentialsError, UnexpectedError } from "../../domain/errors"
import { ThreadModel } from "../../domain/models/thread-model"
import { ThreadService } from "../../domain/usecases/thread-service"
import { HttpPostClient, HttpStatusCode } from "../../protocols/http"
import { threadMapper } from "./utils/thread-mapper"

export class RemoteThreadService implements ThreadService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, ThreadModel[]>
  ) {}

  async loadThread (): Promise<ThreadModel> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/threads/get',
      body: {}
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return threadMapper(httpResponse.body)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadThreads ():Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/threads/get',
      body: {}
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadThreadAttendance (id: number, from: string, to: string): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/getthreadattendance',
      body: {
        thread_id: id,
        from,
        to
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
