import { InvalidCredentialsError, UnexpectedError } from "../../domain/errors"
import { ReferencesModel } from "../../domain/models/references-model"
import { ReferencesService } from "../../domain/usecases/references-service"
import { HttpPostClient, HttpStatusCode } from "../../protocols/http"
import { referencesMapper } from "./mappers/references-mapper"


export class RemoteReferencesService implements ReferencesService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async loadReferences (groupId: number): Promise<ReferencesModel> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/getreferences',
      body: {
        group_id: groupId
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return referencesMapper(httpResponse.body)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadClientProducts (): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2bmanagement/getClientProducts',
      body: {}
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
