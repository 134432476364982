import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getProduct,
  getReferences,
  getReferencesFilter,
  getThread,
} from "../redux/selectors";
import { postService, referencesService, summaryService } from "../api";
import { useAppDispatch } from "../redux/store/hooks";
import { logoutAuth, removeStopSource, setReferences } from "../redux/actions";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import NetworkIcon from "../scripts/network-icon";
import { Button } from "antd";

const StopSourcesList: React.FC = () => {
  const thread = useSelector(getThread);
  const referencesFilter = useSelector(getReferencesFilter);
  const [loading, setLoading] = useState(true);
  const [stopSourceList, setStopSourceList] = useState([]);
  const dispatch = useAppDispatch();
  const [avatarNotLoaded, setAvatarNotLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [competitors, setCompetitors] = useState([]);
  const references = useSelector(getReferences);
  const [client, setClient] = useState({ id: referencesFilter });
  const product = useSelector(getProduct);

  const loadList = (): void => {
    setLoading(true);
    postService.loadListRefStopSource(references[0]).then((resp) => {
      setStopSourceList(resp.items);
      setLoading(false);
    });
  };

  const removeListStopSource = (ownerId: string, networkId: string) => {
    dispatch(removeStopSource(ownerId, networkId, references[0]));
    setLoading(true);
    postService.loadListRefStopSource(references[0]).then((resp) => {
      setStopSourceList(resp.items);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    referencesService
      .loadClientProducts()
      .then((resp) => {
        setClient(resp.find((it: any) => it.id == product)?.client);
        setCompetitors(resp.find((it: any) => it.id == product)?.competitor);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(logoutAuth());
      });
  }, [product]);

  useEffect(() => {
    if (thread) {
      loadList();
    }
  }, [thread, references]);

  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <div className="white-block">
      <p className="block-title">
        Исключенные источники
        <InfoCircleOutlined className="info" onClick={showDrawer} />
      </p>

      <div className="btn-group">
        <Button
          type={references == client?.id ? "primary" : "default"}
          onClick={() => {
            dispatch(setReferences([client?.id]));
            //if (thread) loadList();
          }}
        >
          Моя компания
        </Button>
        {competitors?.map((competitor: any, id) => (
          <Button
            key={id}
            type={references == competitor?.id ? "primary" : "default"}
            onClick={() => {
              dispatch(setReferences([competitor?.id]));
              //if (thread) loadList();
            }}
          >
            {competitor?.title}
          </Button>
        ))}
      </div>
      {loading ? (
        <div className="feed-posts-loader" style={{ height: "300px" }}>
          <LoadingOutlined />
        </div>
      ) : stopSourceList.length == 0 ? (
        "Исключенных источников пока нет"
      ) : (
        <div className="source-list flex-stretch">
          {stopSourceList.map((item: any) => (
            <div key={item.id} className="source_avatar">
              <div className="network-icon source_network">
                <NetworkIcon networkName={item?.network_name} />
              </div>
              <div
                className="source_remove"
                onClick={() =>
                  removeListStopSource(item.profile_id, item.network_id)
                }
              >
                х
              </div>
              <a href={item.url} target="_blank" rel="noreferrer">
                <div className="source_img">
                  {avatarNotLoaded == true ? (
                    <img src="/Avatar.png" className="logo-icon" alt="" />
                  ) : (
                    <img
                      src={item.image}
                      className="logo-icon"
                      alt=""
                      //onError={() => setAvatarNotLoaded(true)}
                    />
                  )}
                </div>
                <p>{item.username}</p>
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StopSourcesList;
