import {
  EyeOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/ru_RU";
import Link from "./icons/Link";
import { useSelector } from "react-redux";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { getReferencesFilter, getThread } from "../redux/selectors";
import { postService } from "../api";
import { findSource } from "../scripts/utils";
import NetworkIcon from "../scripts/network-icon";

const PublicTop: React.FC = () => {
  const [value, setValue] = useState("today");
  const { RangePicker } = DatePicker;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const feedFilter = useSelector(getFeedFilter);
  const [loading, setLoading] = useState(true);
  const [postList, setPostList] = useState<any>([]);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const thread = useSelector(getThread);
  const [type, setType] = useState("attendance");
  const referencesFilter = useSelector(getReferencesFilter);

  useEffect(() => {
    setLoading(true);
    if (thread) {
      let params = {
        thread_id: +thread.id,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        negative: null,
        post_count: 5,
        filter: {
          network_id: [1, 2, 3, 4, 5, 7, 8, 10],
          referenceFilter: referencesFilter,
        },
      };
      postService.loadMainTopicsPosts(params).then((list) => {
        const newPostList: any = [...list];
        newPostList.map(
          (post: any) =>
            (post["err"] = (post?.viewed / post?.attendance).toFixed(
              2
            ))
        );
        newPostList.sort((a: any, b: any) => b[type] - a[type]);
        setPostList(newPostList);
        setLoading(false);
      });
    }
  }, [thread, dateString, type, referencesFilter]);

  const showDrawer = () => {
    setOpen(true);
  };

  const getPostText = (text: string): { __html: string } => {
    return { __html: text };
  };

  const onClose = () => {
    setOpen(false);
  };
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];

  const data: any = [];
  postList?.map((post: any, id: number) => {
    data.push({
      key: id + 1,
      title: post?.title ? post?.title : post?.text,
      network: { name: post?.network_name, logo: post?.author_logo },
      attendance: post?.attendance,
      viewed: post?.network_id != 4 ? post?.viewed : "-",
      err: post?.network_id != 4 ? post?.err : "-",
      uri: post?.uri,
    });
  });

  const columns = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Источник",
      dataIndex: "network",
      key: "network",
      render: (network: any) => (
        <div className="flex">
          {network.name == "gs" ? (
            <img src={network.logo} className="source-icon"></img>
          ) : (
            <NetworkIcon networkName={network.name} />
          )}
          <div className="grey">{findSource(network.name)}</div>
        </div>
      ),
    },
    {
      title: "Публикация",
      dataIndex: "title",
      key: "title",
      width: 400,
      render: (text: any) => <div className="top-post__text" dangerouslySetInnerHTML={getPostText(text)}></div>,
    },
    {
      title: (
        <Tooltip
          color={"#7856FE"}
          title="Фактический охват может немного отличаться, так как в СМИ нельзя точно вычислить посещаемость публикации"
        >
          <div>
            Приблизительный охват
            <EyeOutlined className="info" />
          </div>
        </Tooltip>
      ),
      dataIndex: "attendance",
      key: "attendance",
      render: (num: number) => (
        <div className="flex">{num.toLocaleString()}</div>
      ),
    },
    {
      title: "Просмотров",
      dataIndex: "viewed",
      key: "viewed",
      render: (viewed: number) => (
        <div className="flex">{viewed}</div>
      ),
    },
    {
      title: (
        <div>
          ERR{" "}
          <InfoCircleOutlined
            className="info"
            onClick={() => setOpenDrawer(true)}
          />
        </div>
      ),
      dataIndex: "err",
      key: "err",
    },
    {
      title: "Ссылка",
      dataIndex: "uri",
      key: "uri",
      render: (text: string) => (
        <a href={text} target="_blank">
          Ссылка <Link />
        </a>
      ),
    },
  ];

  const onPeriodChange = (value: any, dateString: string[]): void => {
    console.log("dateString", dateString);
    setDateString(dateString);
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const handleChangeType = (value: string) => {
    setType(value);
  };

  return (
    <div className="white-block">
      <div className="flex-space">
        <div>
          <p className="block-title">
            Топ публикаций{" "}
            <InfoCircleOutlined className="info" onClick={showDrawer} />
          </p>
          <div className="flex">
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    activeBorderColor: "#7856FE",
                  },
                },
              }}
            >
              <Select
                value={type}
                onChange={handleChangeType}
                style={{ width: 150 }}
                allowClear={false}
                options={[
                  { value: "attendance", label: "По охвату" },
                  { value: "err", label: "По ERR" },
                  { value: "viewed", label: "По просмотрам" },
                ]}
              />
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Radio: {
                    buttonSolidCheckedColor: "#7856FE",
                    buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                    colorPrimary: "rgba(0, 0, 0, 0.1)",
                    buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                  },
                },
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChange}
                value={value}
                optionType="button"
                buttonStyle="solid"
              />
            </ConfigProvider>

            {value == "period" && (
              <RangePicker
                format={"DD-MM-YYYY"}
                onChange={onPeriodChange}
                style={{ width: "278px" }}
                className="choose-period"
                allowClear={false}
                locale={locale}
              />
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader">
          <LoadingOutlined />
        </div>
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 12,
              },
            },
          }}
        >
          <Table
            bordered={false}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </ConfigProvider>
      )}
      <Drawer title="Что отображает эта таблица?" onClose={onClose} open={open}>
        <p className="purple-border">
          В таблице представлены ТОП-5 публикаций с упоминаниями компании за
          период, отсортированные по выбранному параметру: охват пользователей,
          ERR или просмотры.
        </p>
        <p className="title-small">Как интерпретировать данные</p>
        <ol>
          <li>
            Временные интервалы: Вы можете выбрать различные временные интервалы
            для анализа динамики.
          </li>
        </ol>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
      <Drawer
        title="Что отображает этот показатель?"
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
      >
        <div className="">
          <p className="purple-border">
            «Уровень вовлеченности по охвату». Данный показатель дает понимание
            о том, какой процент подписчиков просматривает публикации в
            рассматриваемых источниках. Именно «просматривает», текст публикации
            на самом деле может быть и не прочитан подписчиком. Рассчитывается
            как отношение среднего охвата публикации к количеству подписчиков
            канала.
          </p>
        </div>
      </Drawer>
    </div>
  );
};

export default PublicTop;
