import { LogoutOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { referencesService } from "../api";
import { logoutAuth, setProduct, setReferences, setReferencesFilter } from "../redux/actions";
import { getThread } from "../redux/selectors";
import { useAppDispatch } from "../redux/store/hooks";

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState<any>([]);
  const thread = useSelector(getThread);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key == "logout") dispatch(logoutAuth());
    else {
      //message.info(`Выбран продукт ${key}`);
      message.info(`Продукт изменен`);
      dispatch(setProduct(+key));
      dispatch(setReferencesFilter([products.find((it:any) => it.id == key).client.id]))
      dispatch(setReferences([products.find((it:any) => it.id == key).client.id]))
    }
  };

  useEffect(() => {
    referencesService.loadClientProducts().then((resp) => {
      setProducts(resp);
      dispatch(setProduct(resp[0]?.id));
      dispatch(setReferencesFilter([resp[0]?.client?.id]))
      dispatch(setReferences([resp[0]?.client?.id]))
    })
    .catch((err) => {
      dispatch(logoutAuth())
    })
  }, []);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Выбрать продукт",
      disabled: true,
    },
    {
      type: "divider",
    },
  ];

  products.map((product: any) =>
    items.push({ label: product?.client.title, key: product?.id })
  );

  items.push(
    {
      type: "divider",
    },
    {
      label: "Выйти",
      key: "logout",
      icon: <LogoutOutlined />,
    }
  );

  return (
    <div className="logout-icon">
      <Dropdown
        menu={{ items, selectable: true, defaultSelectedKeys: ["2"], onClick }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <img src="/Avatar.png" width={48} />
        </a>
      </Dropdown>
    </div>
  );
};

export default Logout;
