import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Colors,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { ConfigProvider, Switch } from "antd";

type Props = {
  graphData: any;
  title?: string
};

const LineChart: React.FC<Props> = ({ graphData, title }: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  Chart.register(
    Colors,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    ArcElement,
    ChartDataLabels,
    PointElement,
    LineElement,
    Filler
  );

  const months = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];

  const getLabels = () => {
    return graphData?.map((item: any) => {
      let dateSeparator = "-";

      if (item[0].includes(dateSeparator)) {
        let dateArr = item[0].split("-");
        let month = parseInt(dateArr[1]) - 1;

        return item[0].slice(-2) + " " + months[month];
      }

      return item[0];
    });
  };

  const data = {
    labels: getLabels(),
    datasets: [
      {
        label: title ? title: "Просмотры",
        data: graphData?.map((it: any) => it[1]),
        fill: true,
        backgroundColor: "rgba(120,86,254,0.2)",
        borderColor: "#7856FE",
        tension: 0.3,
        pointHoverBackgroundColor: "white",
        pointHoverBorderWidth: 3,
        pointRadius: 2,
        pointHoverRadius: 7,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options: any = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      enabled: true,
      mode: "x",
      sensitivity: 0.5,
    },
  };

  return (
    <div>
      <Line data={data} options={options} width={"600px"} />
    </div>
  );
};

export default LineChart;
