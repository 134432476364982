import { makeApiUrl } from "./factories/http/api-url-factory"
import { makeAxiosHttpClient } from "./factories/http/axios-http-client-factory"
import { RemoteAuthentication } from "./usecases/authentication/remote-authentication"
import { RemotePostService } from "./usecases/post/remote-post-service"
import { RemoteReferencesService } from "./usecases/references/remote-references-service"
import { RemoteSummaryService } from "./usecases/summary/remote-summary-service"
import { RemoteThreadService } from "./usecases/thread/remote-thread-service"
import { RemoteUserService } from "./usecases/user/remote-user-service"


const axiosHttpClient = makeAxiosHttpClient()

export const authService = new RemoteAuthentication(makeApiUrl('/authorization'), axiosHttpClient)
export const userService = new RemoteUserService(makeApiUrl(''), axiosHttpClient)
export const postService = new RemotePostService(makeApiUrl(''), axiosHttpClient)
export const threadService = new RemoteThreadService(makeApiUrl(''), axiosHttpClient)
export const referencesService = new RemoteReferencesService(makeApiUrl(''), axiosHttpClient)
export const summaryService = new RemoteSummaryService(makeApiUrl(''), axiosHttpClient)

