import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import {
  PostService,
  LoadPostsParams,
  LoadSummaryInfoParams,
  LoadPostsResponse,
  LoadMainTopicsPostsParams
} from '../../domain/usecases/post-service'
import { loadPostsMapper, loadStopPostsMapper } from '../../usecases/post/utils/load-posts-mapper'
import { MainTopicsPostModel } from '../../domain/models/main-topics-post-model'
import { mainTopicsPostsMapper } from '../../usecases/post/utils/main-topics-posts-mapper'

export class RemotePostService implements PostService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async getSummaryInfo (params: LoadSummaryInfoParams): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/getpostcount',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadPosts (params: LoadPostsParams): Promise<LoadPostsResponse> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/posts',
      body: {
        thread_id: params.id,
        from: params.from,
        to: params.to,
        limit: 20,
        start: params.startPostIndex,
        sort:  params.sort,
        filter: params.filter
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return loadPostsMapper(httpResponse.body)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadListStopPost (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/listRefStopPost',
      body: {
        thread_id: params.id,
        limit: 20,
        start: params.startPostIndex,
        reference_item_id: params.reference_item_id
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return loadStopPostsMapper(httpResponse.body)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadSinglePost (params: any): Promise<LoadPostsResponse> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/getSinglePost',
      body: {
        post_id: params.id,
        owner_id: params.owner_id,
        network_id: params.network_id,
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadListRefStopPost (reference_item_id: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/listrefstoppost',
      body: {reference_item_id}
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadListRefStopSource (reference_item_id: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/listrefstopsource',
      body: {reference_item_id}
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadRefSources (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/inpd/getRefSources',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadPostAttendance (id: string, ownerId: string, networkId: string): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/getpostattendance',
      body: {
        id,
        owner_id: ownerId,
        network_id: networkId
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadMainTopicsPosts (params: LoadMainTopicsPostsParams): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/getposttoptrust',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return (httpResponse.body)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCurrentSmi (): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/currentsmi',
      body: {}
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async addRefStopPost (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/addrefstoppost',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async addStopPost (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/thread/addstoppost',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async removeStopPost (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/thread/removestoppost',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async removeRefStopPost (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/removerefstoppost',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async setPostTrust (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/setposttrust',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getPostSiblings (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/getpostsiblings',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async addStopSource (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/addRefStopSource',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async removeStopSource (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/removeRefStopSource',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async sendChangeTonalRequest (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/messageTrustRequest',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCommentaries (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/commentaries',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadChildCommentaries (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/childcommentaries',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
