import React from "react";

const IconReply: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2033 16.59L16.75 4.32333C16.9975 3.655 16.3467 3.005 15.6792 3.2525L3.40833 7.80249C2.64083 8.08749 2.70083 9.19249 3.49499 9.39166L9.18999 10.8225L10.6125 16.5025C10.8125 17.2975 11.9183 17.3583 12.2033 16.59V16.59Z"
        stroke="#1D1929"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5583 3.44141L9.19165 10.8247"
        stroke="#1D1929"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconReply;
