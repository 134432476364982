import "../App.css";
import NumberOfMentions from "../components/NumberOfMentions";
import TonalStatistic from "../components/TonalStatistic";
import BestSources from "../components/BestSources";
import PublicTop from "../components/PubicTop";
import BestThemes from "../components/BestThemes";
import Metrics from "../components/Metrics";
import MediaIndex from "../components/MediaIndex";
import { resetFeedFilter } from "../redux/actions";
import { useAppDispatch } from "../redux/store/hooks";

function Main() {
  const dispatch = useAppDispatch();
  dispatch(resetFeedFilter())
  
  return (
    <div className="App">
      <h1>Главная</h1>
      <NumberOfMentions />
      <TonalStatistic />
      <MediaIndex/>
      <BestSources />
      <PublicTop />
      <BestThemes />
      <Metrics />
    </div>
  );
}

export default Main;
